.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  color: white !important;
}


.login-container {
  display: flex;
}


.error {
  color: #ed1c23;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-10px {
  padding-top: 10px;
}

.login-btn {
  margin-top: 30px;
  background-color: black !important;
}

.ten-perc-p {
  padding: 20%;
}

.body {
  background-color: #E44E24;
}

.card {
  width: 800px;
}

.email-client-input {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 40px;
}

.send-btn {
  background-color: #282c34 !important;
  color: white !important;
  margin-bottom: 40px;
}

.label {
  color: white;
}

.log-out {
  position: absolute !important;
  top: 0;
  right: 0;
  margin-right: 20px !important;
  margin-top: 5px !important;
}